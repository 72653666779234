import React from "react";

const BackButtonSvg = () => {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.16602 10.8337L8.58268 13.2503C8.73546 13.4031 8.81185 13.5975 8.81185 13.8337C8.81185 14.0698 8.73546 14.2642 8.58268 14.417C8.4299 14.5698 8.23546 14.6462 7.99935 14.6462C7.76324 14.6462 7.56879 14.5698 7.41602 14.417L3.58268 10.5837C3.49935 10.5003 3.44032 10.41 3.4056 10.3128C3.37088 10.2156 3.35352 10.1114 3.35352 10.0003C3.35352 9.88921 3.37088 9.78505 3.4056 9.68783C3.44032 9.5906 3.49935 9.50033 3.58268 9.41699L7.41602 5.58366C7.56879 5.43088 7.76324 5.35449 7.99935 5.35449C8.23546 5.35449 8.4299 5.43088 8.58268 5.58366C8.73546 5.73644 8.81185 5.93088 8.81185 6.16699C8.81185 6.4031 8.73546 6.59755 8.58268 6.75033L6.16602 9.16699H17.166C17.4021 9.16699 17.6 9.24685 17.7598 9.40658C17.9195 9.5663 17.9993 9.76421 17.9993 10.0003C17.9993 10.2364 17.9195 10.4344 17.7598 10.5941C17.6 10.7538 17.4021 10.8337 17.166 10.8337H6.16602Z" fill=""/>
    </svg>
  )
}

export default BackButtonSvg;
