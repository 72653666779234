import { createSlice } from "@reduxjs/toolkit";

const initialLoaderState = {
  actionLoader: false,
  apiCount: 0,
};

export const LoaderSlice = createSlice({
  name: "loader",
  initialState: initialLoaderState,
  reducers: {
    setLoader: (state) => {
      state.apiCount++;
      state.actionLoader = true;
    },
    decreaseApiCount: (state) => {
      state.apiCount--;
      if (state.apiCount <= 0) {
        state.actionLoader = false;
        state.apiCount = 0; // Reset to 0 for clarity
      }
    }, 
  },
});
