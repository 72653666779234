import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { DataConstant, UrlConstant } from "../constants";
import { actionTypes } from "../modules/widget/_redux/widgetRedux";
import { handleFetchGet, handleFetchPost } from "../../utils/widgetApiCallsProvider";
import { findMessage, formatAddressString } from "../../utils/helper";

const useServiceDetails = () => {

  const widgetDetail = useSelector(state => state.widget);
  const dispatch = useDispatch();

  const handleError = (response) => {
    if (response?.message && response.message.includes(DataConstant.Exception)) {
      NotificationManager.error(DataConstant.ExceptionMsg);
    }
    else if (response?.message) {
      NotificationManager.error(findMessage(response.message));
    }
  }

  const getFacilitiesForFacilityBooking = () => {
    const payload = {
      lat: widgetDetail?.currentLocationDetail?.lat ? widgetDetail?.currentLocationDetail?.lat : null,
      long: widgetDetail?.currentLocationDetail?.long ? widgetDetail?.currentLocationDetail?.long : null,
      serviceCategoryIds: '',
      businessId: widgetDetail?.businessDetail?.businessId,
      userDetailId : null,
      currentDateTime : null,
      currentTimeStr : null,
    }
    return handleFetchPost(
      UrlConstant.widget.getWidgetFacilities,
      JSON.stringify(payload)
    )
  }

  const selecteFacility = (response, facilityId) => {
    if (
      response &&
      response.status?.toLowerCase() ===
      DataConstant.apiResponseStatus.success.toLowerCase() &&
      response.data
    ) {
      const selectedFacility = response.data?.find(f => f.facilityId == facilityId);
      if (!selectedFacility) {
        NotificationManager.error("Facility not found");
        dispatch([{
          type: actionTypes.SetWidgetTab,
          payload: {
            currentTab: DataConstant.tabs.facility
          },
        }]);
        return null;
      }
      dispatch({
        type: actionTypes.SetFacilityDetail,
        payload: {
          facilityModel: {
            facilityId: selectedFacility.facilityId,
            facilityName: selectedFacility.name,
            facilityLocation: formatAddressString(selectedFacility?.addressLine1, selectedFacility?.addressLine2, selectedFacility?.city, selectedFacility?.state, selectedFacility?.zipCode, selectedFacility?.country, null, null),
            noPenaltyUptoHours: selectedFacility.noPenaltyUptoHours,
            cancelledBookingCharge: selectedFacility.cancelledBookingCharge,
            businessClubId : selectedFacility.businessClubId,
            isClubReady : selectedFacility?.businessClubId > 0 ?  true : false,
            lat: selectedFacility.lat,
            long: selectedFacility.long
          }
        }
      });
      return selectedFacility;
    }
    else {
      handleError(response);
      return null;
    }
  };

  const getServiceData = (serviceId) => {
    const url = `${UrlConstant.widget.GetServiceByIdForAll}/${serviceId}`

    return handleFetchGet(url);

  }

  const setServiceData = (response, serviceId, facilityId) => {
    if (
      response &&
      response.status?.toLowerCase() ===
      DataConstant.apiResponseStatus.success.toLowerCase() &&
      response.data
    ) {
      const selectedService = response.data;
 
      const isPSFService = selectedService?.isPatientSpecificService;

      if (!isPSFService || !selectedService) {
        NotificationManager.error("invalid service");
        dispatch([{
          type: actionTypes.SetWidgetTab,
          payload: {
            currentTab: DataConstant.tabs.service
          },
        }]);
        return null;
      }
      selectedService["qty"] = 1;
      dispatch([{
        type: actionTypes.SetServiceDetail,
        payload: {
          selectedServiceList: [selectedService],
          selectedAddOnList: [],
          isPSFServiceSelected: isPSFService,
          labsRequired: selectedService?.labsRequired,
          preAssessmentRequired: selectedService?.preAssessmentRequired,
          totalAmount: 0,
        },
      }, {
        type: actionTypes.SetWidgetTab,
        payload: {
          currentTab: DataConstant.tabs.psfServiceDetails
        },
      },
      {
        type: actionTypes.SetBookingChoice,
        payload: {
          isCallOutService: !facilityId,
          isDisplayBookingChoice: widgetDetail?.isDisplayBookingChoice
        },
      }]);
      return selectedService;
    } else {
      handleError(response);
      dispatch([{
        type: actionTypes.SetWidgetTab,
        payload: {
          currentTab: DataConstant.tabs.service
        },
      }]);
      return null;
    }

  };
  const featchData = async (serviceId, facilityId) => {
    try {
      let selectedFacility = null;
      if (facilityId) {
        const facilityResponse = await getFacilitiesForFacilityBooking();

        selectedFacility = selecteFacility(facilityResponse, facilityId);
      }
      if (selectedFacility || !facilityId) {
        const serviceResponse = await getServiceData(serviceId);
        setServiceData(serviceResponse, serviceId, facilityId);
      }
    } catch (e) {
      NotificationManager.error(e?.message);
    }
  }
  return featchData;
}

export default useServiceDetails;
