import { useDispatch, useSelector } from "react-redux";
import { DataConstant, UrlConstant } from "../constants";
import { NotificationManager } from "react-notifications";
import { findMessage } from "../../utils/helper";
import { handleAuthFetchGet } from "../../utils/apiCallsProvider";
import { actionTypes } from "../modules/widget/_redux/widgetRedux";

const useBusinessDetails = () => {
  const dispatch = useDispatch();
  const widgetDetail = useSelector(state => state.widget);
  const updateBusinessDetails = async (callBack=(stateId=0) => {}, trustState=false) => {
    const businessId = widgetDetail?.businessDetail?.businessId;
    const stateId = trustState ? widgetDetail?.businessDetail?.stateId : null;
    const getBusinessDetailsUrl = UrlConstant.common.GetBusinessById(businessId);
    if (stateId) {
      callBack(stateId);
    } else {
      const response = await handleAuthFetchGet(getBusinessDetailsUrl);
      if (
        response &&
        response.status?.toLowerCase() ===
        DataConstant.apiResponseStatus.success.toLowerCase() &&
        response.data
      ) {
          if (response.data?.stateId) {
            dispatch({
              type: actionTypes.UpdateBusinessDetail,
              payload: {
                stateId: response.data?.stateId,
              },
            });
            callBack(response.data?.stateId);
          } else {
            NotificationManager.error("Business stateId cannot be null")
          }
          return response.data
      } else {
          NotificationManager.error(findMessage(response.message));
          return
      }
    }
  }
  const getFeatureFlag = async () => {
    const getBusinessDetailsUrl = UrlConstant.common.GetFeatureFlags;
    const response = await handleAuthFetchGet(getBusinessDetailsUrl);
    if (
      response &&
      response.status?.toLowerCase() ===
      DataConstant.apiResponseStatus.success.toLowerCase() &&
      response.data
    ) {
        dispatch({
          type: actionTypes.SetFeatureFlag,
          payload: response.data,
        });
        return response.data
    } else {
        NotificationManager.error(findMessage(response.message));
        return
    }
  }
  return {updateBusinessDetails, getFeatureFlag};
}

export default useBusinessDetails;
