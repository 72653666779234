export const TenantConfig = {
  telemedicine: {
    clientId: "fab9f608-3295-42c6-9ced-a5977a1e85c4",
    authority: "https://telemedicinedev.b2clogin.com",
    signUpSignIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignIn_Widget",
    redirectURL: "https://localhost:3000/widget",
    postLogoutRedirectURL: "https://localhost:3000/widget",
    loginScope:
      "https://telemedicinedev.onmicrosoft.com/ecbce02b-6a45-411d-8d1c-c2a487e4b932/api_read",
    resetPassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
  "telemedicine-dev-ui": {
    clientId: "9a4e7373-f3ed-4691-bea5-1e3112003873",
    authority: "https://telemedicinedev.b2clogin.com",
    signUpSignIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_SignIn_Widget",
    redirectURL: "https://telemedicine-bookingwidget-test.azurewebsites.net/widget",
    postLogoutRedirectURL: "https://telemedicine-bookingwidget-test.azurewebsites.net/widget",
    loginScope:
      "https://telemedicinedev.onmicrosoft.com/ecbce02b-6a45-411d-8d1c-c2a487e4b932/api_read",
    resetPassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://telemedicinedev.b2clogin.com/telemedicinedev.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
  "hydreight-backoffice-test": {
    clientId: "a161bcbe-6d6d-4721-adab-5131c9224318",
    authority: "https://telemedhydreighttest.b2clogin.com",
    signUpSignIn:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_SignIn_Widget",
    // redirectURL: "http://localhost:3000/widget",
    // postLogoutRedirectURL: "http://localhost:3000/widget",
    redirectURL: "https://booking-stage.hydreight.com/widget",
    postLogoutRedirectURL: "https://booking-stage.hydreight.com/widget",
    loginScope:
      "https://telemedhydreighttest.onmicrosoft.com/f9baf533-adb1-4c0d-b814-88930dca3af7/api_read",
    resetPassword:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://telemedhydreighttest.b2clogin.com/telemedhydreighttest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
  "hydreight-backoffice-prod": {
    clientId: "54a52a6b-ace7-434d-a733-9d2a2e1cf07a",
    authority: "https://hydreightprod.b2clogin.com",
    signUpSignIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_SignIn_Widget",
    redirectURL: "https://booking.hydreight.com/widget",
    postLogoutRedirectURL: "https://booking.hydreight.com/widget",
    loginScope:
      "https://hydreightprod.onmicrosoft.com/b221b833-f8b2-4c7d-a404-94464e5bc411/api_read",
    resetPassword:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://hydreightprod.b2clogin.com/hydreightprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
  telemedicinedripbar: {
    clientId: "e82f08d4-1d03-4955-bdee-fc06f4fba13c",
    authority: "https://telemeddripbar.b2clogin.com",
    signUpSignIn:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    redirectURL:
      "https://telemedicinedripbar-bookingwidget-test.azurewebsites.net/widget",
    postLogoutRedirectURL:
      "https://telemedicinedripbar-bookingwidget-test.azurewebsites.net/widget",
    // redirectURL: "https://localhost:3000/widget",
    // postLogoutRedirectURL: "https://localhost:3000/widget",
    loginScope:
      "https://telemeddripbar.onmicrosoft.com/d7fefea9-ed2f-415c-9cae-7eddea424699/api_read",
    resetPassword:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://telemeddripbar.b2clogin.com/telemeddripbar.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
  },
  "dripbar-backoffice-prod": {
    clientId: "022bb371-882c-49f8-a52a-6eefd6d8844d",
    authority: "https://dripbarprod.b2clogin.com",
    signUpSignIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    redirectURL: "https://booking.thedripbar.com/widget",
    postLogoutRedirectURL: "https://booking.thedripbar.com/widget",
    loginScope:
      "https://dripbarprod.onmicrosoft.com/3e597de9-e0ce-4faf-99a1-f6669816b68c/api_read",
    resetPassword:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://dripbarprod.b2clogin.com/dripbarprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
  },
  "vsdigital-backoffice-test": {
    clientId: "5e288c2c-4035-47e9-bbc6-3db10e0d5b40",
    authority: "https://vsdigitaltest.b2clogin.com",
    signUpSignIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_SignIn_Widget",
    redirectURL: "https://vsdigital-bookingwidget-test.azurewebsites.net/widget",
    postLogoutRedirectURL: "https://vsdigital-bookingwidget-test.azurewebsites.net/widget",
    // redirectURL: "https://localhost:3000/widget",
    // postLogoutRedirectURL: "https://localhost:3000/widget",
    loginScope:
      "https://vsdigitaltest.onmicrosoft.com/ee360dc2-8a7e-487d-a605-089b9453118e/api_read",
    resetPassword:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://vsdigitaltest.b2clogin.com/vsdigitaltest.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
  "vsdigital-backoffice-prod": {
    clientId: "3ede215c-efb3-466e-8f16-360e380c36dd",
    authority: "https://vsdigitalprod.b2clogin.com",
    signUpSignIn:
      "https://vsdigitalprod.b2clogin.com/vsdigitalprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signIn:
      "https://vsdigitalprod.b2clogin.com/vsdigitalprod.onmicrosoft.com/B2C_1_SignUp_SignIn_Widget",
    signInWidget:
      "https://vsdigitalprod.b2clogin.com/vsdigitalprod.onmicrosoft.com/B2C_1_SignIn_Widget",
    redirectURL:
      "https://vsdigital-bookingwidget-prod.azurewebsites.net/widget",
    postLogoutRedirectURL:
      "https://vsdigital-bookingwidget-prod.azurewebsites.net/widget",
    loginScope:
      "https://vsdigitalprod.onmicrosoft.com/2a110b61-1b1a-4644-a628-52696dfc1466/api_read",
    resetPassword:
      "https://vsdigitalprod.b2clogin.com/vsdigitalprod.onmicrosoft.com/B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    passwordReset: "B2C_1A_TrustFrameworkExtensions_PasswordResetAccountExists",
    changePassword:
      "https://vsdigitalprod.b2clogin.com/vsdigitalprod.onmicrosoft.com/B2C_1_ChangePassword",
    passwordChange: "B2C_1_ChangePassword",
    signInPolicy: "B2C_1_SignUp_SignIn_Widget",
    signInWidgetPolicy: "B2C_1_SignIn_Widget",
  },
};
