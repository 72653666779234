/* eslint-disable */
import * as authProvider from "./authProvider";
import store from "../redux/store";
import { DataConstant } from "../app/constants";
import { decreaseCounter, increaseCounter } from "./apiCallsProvider";
const WhiteLabel = `${process.env.REACT_APP_WHITELABELNAME}`;

export const getAuthToken = () => {
    const {
        widget: { widgetToken }
    } = store.getState();
    return widgetToken;
};

export const handleFetchGet = async (
    requestURL,
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "get",
            headers: {
                "content-type": "application/json;",
                "WhiteLabel": WhiteLabel
            },
        };
        increaseCounter();
        if (authorizationRequired) {
            options.headers.token = getAuthToken();
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responseJson.statusCode === 401) {
                            console.log("responseJson-widgetApiCallsProvider",responseJson);
                            window.location.href = DataConstant.Error401Page;
                            return;
                        }
                        else if (responseJson.statusCode === 404) {
                            window.location.href = DataConstant.Error404Page;
                            return;
                        }
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });

        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responseJson.statusCode === 401) {
                            console.log("responseJson-widgetApiCallsProvider",responseJson);
                            window.location.href = DataConstant.Error401Page;
                            return;
                        }
                        else if (responseJson.statusCode === 404) {
                            window.location.href = DataConstant.Error404Page;
                            return;
                        }
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });

export const handleFetchPost = async (
    requestURL,
    requestBody,
    requestHeader = "application/json;",
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "post",
            headers: {
                "content-type": "application/json;",
                "WhiteLabel": WhiteLabel
            },
            body: requestBody,
        };

        if (requestHeader) {
            options.headers["content-type"] = `${requestHeader}`;
        }
        let responsedata;
        increaseCounter();
        if (authorizationRequired) {
            options.headers.token = getAuthToken();
            fetch(requestURL, options)
                .then(
                    (response) => {
                        responsedata = response;
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responsedata.status !== 200) {
                            reject(responseJson);
                        }
                        else {
                            resolve(responseJson);
                        }
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });

        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responseJson.statusCode === 401) {
                            console.log("responseJson-widgetApiCallsProvider",responseJson);
                            window.location.href = DataConstant.Error401Page;
                            return;
                        }
                        else if (responseJson.statusCode === 404) {
                            window.location.href = DataConstant.Error404Page;
                            return;
                        }
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });

export const handleFetchFileUploadPost = async (
    requestURL,
    requestBody,
    authorizationRequired = true
) =>
    new Promise((resolve, reject) => {
        const options = {
            method: "post",
            headers: {
                Accept: "application/json",
            },
            body: requestBody,
        };
        increaseCounter();
        if (authorizationRequired) {
            options.headers.token = getAuthToken();
            fetch(requestURL, options)
                .then(
                    (response) => {
                        responsedata = response;
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        if (responseJson.statusCode === 401) {
                            console.log("responseJson-widgetApiCallsProvider",responseJson);
                            window.location.href = DataConstant.Error401Page;
                            return;
                        }
                        else if (responseJson.statusCode === 404) {
                            window.location.href = DataConstant.Error404Page;
                            return;
                        }
                        if (responsedata.status !== 200) {
                            reject(responseJson);
                        } else {
                            resolve(responseJson);
                        }
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });

        } else {
            fetch(requestURL, options)
                .then(
                    (response) => {
                        return response.json();
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .then(
                    (responseJson) => {
                        resolve(responseJson);
                    },
                    (err) => {
                        reject(err);
                    }
                )
                .catch((err) => {
                    reject(err);
                })
                .finally(() => {
                    decreaseCounter();
                });
        }
    });
