import React from 'react'
import BusinessProfile2, { WidgetNavigation } from './widget-businessProfile-2';
import styles from "./widgetLayout.module.css";
import { useSelector } from 'react-redux';
import { DataConstant } from '../../constants';
import BackButtonSvg from '../../assets/svg/BackButton';
const { REACT_APP_WHITELABELNAME_DISPLAYNAME } = process.env;
/**
 * WidgetLayout component.
 *
 * This component provides a consistent layout for widget pages, with a standard structure
 * for displaying the widget's main content, top navigation, actions, and a banner (optional).
 *
 * @param {React.ReactNode} children The main content of the widget page.
 * @param {React.ReactNode} actions The actions to be displayed at the bottom of the page.
 * @param {React.ReactNode} topNav The custom navigation to be displayed at the top of the page.
 * @param {boolean} [showBanner=true] Whether to show the banner. Default is true, but this is an experimental feature and should not be turned off.
 * @param {object} history The navigation history object. This is required for navigating to other pages.
 * @param {object} leftActionButton Left action button.
 * @param {object} leftFull If left button should take full width in mobile.
 * @param {object} rightActionButton Right action button.ed for navigating 
 * @param {object} leftFull If right button should take full width in mobile.
 * @returns {JSX.Element} The rendered WidgetLayout component.
 *
 * @example
 * <WidgetLayout
 *  children={<WidgetContent />}
 *  actions={<WidgetActions />}
 *  topNav={<WidgetTopNav />}
 *  history={useHistory()}
 * />
 */
const WidgetLayout = ({children, actions, topNav, showBanner=true, history, leftActionButton, leftFull, rightActionButton, rightFull, buttons, showBusinessInMobile}) => {
  const widgetDetail = useSelector((state) => state.widget);
  return (
    <div className={styles.mainContainer}>
      {showBanner && <div className={styles.bannerContainer}>
        <WidgetNavigation history={history}/>
      </div>}
      <div className={styles.mainContentContainer}>
        <div className={styles.left}>
          <BusinessProfile2 widgetURL={widgetDetail?.widgetUrl} businessDetail={widgetDetail?.businessDetail} history={history} showBusinessInMobile={showBusinessInMobile} />
        </div>
        <PageLayout
          topNav={topNav}
          actions={actions}
          leftActionButton={leftActionButton}
          leftFull={leftFull}
          rightActionButton={rightActionButton}
          rightFull={rightFull}
          buttons={buttons}
        >
          {children}
        </PageLayout>
      </div>
    </div>
  )
}

export default WidgetLayout;

//#region Partial Widget Layout can be used in one place and PageLayout can be a seperate page with topNav, page and actions

export const PartialWidgetLayout = ({children, showBanner=true, history, showBusinessInMobile}) => {
  const widgetDetail = useSelector((state) => state.widget);
  return (
    <div className={styles.mainContainer}>
      {showBanner && <div className={styles.bannerContainer}>
        <WidgetNavigation history={history}/>
      </div>}
      
      <div className={styles.mainContentContainer}>
        <div className={styles.left}>
          <BusinessProfile2 widgetURL={widgetDetail?.widgetUrl} businessDetail={widgetDetail?.businessDetail} history={history} showBusinessInMobile={showBusinessInMobile}/>
        </div>
        {children}
      </div>
    </div>
  )
}

export const PageLayout = ({children, topNav, actions, leftActionButton, leftFull, rightActionButton, rightFull, buttons, layoutPageContainerRef}) => {
  return (
    <div className={styles.right}>
      <div className={styles.rightInnerContainer}>
        <div className={styles.navigationContainer}>
          {topNav}
        </div>
        <div className={styles.doubleContainer}> 
        <LayoutPageContainer ref={layoutPageContainerRef}>
          {children}
        </LayoutPageContainer>
        <LayoutActionContainer
          actions={actions}
          leftActionButton={leftActionButton}
          leftFull={leftFull}
          rightActionButton={rightActionButton}
          rightFull={rightFull}
          buttons={buttons}
        />
        </div>
      </div>
    </div>
  )
}

//#endregion 

//#region local components 
const LayoutPageContainer = React.forwardRef(({ children }, ref) => {
  return (
    <div className={styles.topContainer} ref={ref}>
      {children}
    </div>
  );
});

const LayoutActionContainer = ({actions, leftActionButton, leftFull, rightActionButton, rightFull, buttons}) => {
  return (
    actions ? <div className={styles.bottomContainer}>
      <div className={`${styles.buttonContainer} border-top`}>
      {leftActionButton && (
        <div className={`${styles.buttonWrapper} ${leftFull ? styles.fullWidth : ''}`}>
          {leftActionButton}
        </div>
      )}
      {rightActionButton && (
        <>
          {!leftActionButton && <div className={styles.buttonWrapper}></div>}
          <div className={`${styles.buttonWrapper} ${rightFull ? styles.fullWidth : ''}`}>
            {rightActionButton}
          </div>
        </>
      )}
      {buttons}
      </div>
    </div> : <></>
  )
}
//#endregion

export const TopNav = ({onGoback, pageName="", subHeaderName="", inlineButtonWithTitle}) => {
  return (
    <div className="cm-flex-1 d-flex flex-column my-1 my-md-4">
      <div className={`d-flex ${inlineButtonWithTitle ? "" : "flex-column"}`}>
        {onGoback && <button
          type="button"
          className={`btn btn-clean font-weight-bold ${styles.navBackButton}`}
          onClick={onGoback}
        >
          <BackButtonSvg />
        </button>}
        {pageName && <div className="d-flex">
          <h5 className="my-1 font-700 font-size-20">{pageName}</h5>
        </div>}
      </div>
      {subHeaderName && <div className="d-flex">
          <p className="my-2 ">{subHeaderName}</p>
      </div>}
    </div>
  )
}

export const WidgetTopNav = ({children, onGoback, pageName="", headerName, subHeaderName=""}) => {
  return (
    <div className="d-flex flex-column cm-flex-1" >
      {headerName && <div className="d-flex align-items-center justify-content-start mt-4">
        <h3 className={`text-center text-header m-0 mt-3`}>
          {headerName}
        </h3>
      </div>}
      {(pageName || onGoback ) && <TopNav onGoback={onGoback} pageName={pageName} subHeaderName={subHeaderName} inlineButtonWithTitle={headerName}/>}
      {children}
    </div>
  )
}


export const RightActionButton = ({onClick, title, ...rest}) => {
  return (
    <div className={`text-right pr-0 rowl-md-6`}>
      <button
        {...rest}
        type="button"
        onClick={onClick}
        className={`btn cm-btn-color ${rest?.className}`}
      >
        {title}
      </button>
    </div>
  )
}

export const LeftDateInfoButton = ({topText, bottomText, ...rest}) => {
  return (
    <div className={`pl-0 row-md-6`}>
      <span className="font-weight-bold font-size-sm">
        {topText}
      </span>
      <div className="d-flex align-items-center justify-content-between">
        <h5 className="font-size-h2 font-weight-bolder">
          {bottomText}
        </h5>
      </div>
    </div>
  )
}


export const LeftActionButtonOutline = ({onClick, title, ...rest}) => {
  return (
    <div className="col-md-6 pl-0">
      <button
        {...rest}
        type="button"
        className={`btn cm-btn-outline-primary ${rest?.className}`}
        onClick={onClick}
      >
        {title}
      </button>
    </div>
  )
}
